import React from "react"
import { Link, graphql } from "gatsby"
import * as PropTypes from "prop-types"
import Img from "gatsby-image"
import Layout from "../components/layout"

const propTypes = {
    data: PropTypes.object.isRequired,
}

class PropertyTemplate extends React.Component {
    render() {
        const property = this.props.data.contentfulProperty
        const {
            title,
            description,
            reserved,
        } = property
        return (
            <Layout>
                <h1>Immobilien zum Verkauf</h1>
                <h2>{title}</h2>
                <div
                    style={{
                        display: `flex`,
                        alignItems: `center`,
                    }}
                >
                    <h1>{title}</h1>
                    <h4>{description.description}</h4>
                </div>
            </Layout>
        )
    }
}

PropertyTemplate.propTypes = propTypes

export default PropertyTemplate

export const pageQuery = graphql`
  query($id: String!) {
    contentfulProperty(id: { eq: $id }) {
        title
        description  {
            description
        }
        reserved
    }
}
`